@import 'breakpoints.scss';
@import 'sizes.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex: 0 1 $maxWidth;
  flex-direction: column;
  justify-content: flex-start;
  color: $fontColorLight;
  text-shadow: 0 2px 4px transparentize($black, 0.9);

  @include to(2) {
    align-items: center;
    padding: 80px 20px 0;
  }

  @include from(3) {
    align-items: flex-start;
    padding: 150px 0 50px;
  }
}

.row {
  position: relative;
  overflow: hidden;

  @include to(1) {
    min-height: 500px;
  }

  @include from(2) {
    min-height: 500px;
  }
}

.hero {
  position: absolute;
  z-index: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.content {
  display: flex;
  z-index: 1;
  flex-direction: column;
  max-width: 690px;

  @include at(1) {
    margin-left: 16px;
  }

  @include from(3) {
    align-items: flex-start;
  }

  @include to(3) {
    align-self: flex-start;
  }
}

.headline {
  font-weight: 600;

  @include to(1) {
    margin-top: 30px;
    font-size: 46px;
  }

  @include to(2) {
    line-height: 1em;
  }

  @include from(2) {
    font-size: 90px;
    line-height: 1.1em;
  }
}

.subline {
  @include to(1) {
    font-size: 21px;
  }

  @include to(2) {
    margin-bottom: 60px;
  }

  @include from(2) {
    font-size: 18px;
  }

  @include from(3) {
    margin-bottom: 50px;
  }
}

.cta {
  display: flex;
  z-index: 1;
  flex-direction: column;

  @include to(3) {
    align-self: flex-start;
  }
}

.button {
  display: block;
  margin-bottom: 80px;
  text-align: center;
}

.image {
  @include at(1) {
    display: none;
  }
}

.mobileImage {
  @include from(2) {
    display: none;
  }
}

.badges {
  margin-bottom: 20px;

  @include from(3) {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
}

.badge {
  height: 45px;
  margin: 0 10px 20px;
}
