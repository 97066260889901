@import 'breakpoints.scss';
@import 'colors.scss';
@import 'utils.scss';

.heroOuter {
  .hero {
    margin-bottom: 12px;
    padding: 0;

    @include from(3) {
      border-radius: 0 0 10px 10px;
    }
  }
}

.items {
  display: grid;
  gap: 28px 15px;
  grid-template-columns: 3fr 1fr;
  width: 100%;
  padding-top: 14px;
  color: $grey800;
  font-size: 16px;
  line-height: 20px;
}
