.logos {
  --row-gap: 32px;

  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-evenly;
  margin-top: 25px;

  /*
    iOS Safari < 14.8 doesn't support row-gap for flex so we're using
    bottom margin on the imgs. this pulls up the gap under the last row.
    */
  margin-bottom: calc(var(--row-gap) * -1);

  img {
    @media screen and (width <= 359px) {
      margin: 0 16px var(--row-gap);
    }

    margin: 0 8px var(--row-gap);
  }
}
