@import 'colors.scss';

.container {
  text-align: center;

  .img {
    width: 100%;
  }

  h1 {
    color: map-get($componentLibraryPallet, 'coolPurple600');
    font-family: Averta;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 30px;
  }

  h2 {
    color: map-get($componentLibraryPallet, 'coolPurple700');
    font-family: Averta;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 15px 0;
  }
}
