.container {
  display: grid;
  grid-template-columns: 1fr min-content;
  font-size: 16px;
}

.featured {
  grid-column: 1 / -1; // "best value", etc.
}

.title.title {
  font-size: 16px;
  font-weight: normal;
}

.price {
  line-height: 1.2;
}

.billing {
  grid-column: 1 / -1;
  margin-top: 2px;
  color: #4c585f;
  font-size: 14px;
}
