@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  .timerWrapper {
    width: 100%;
    padding: 28px 31px;
    border-radius: 10px;
    background-color: $greenMediumOpacity15;
    font-size: 20px;
    line-height: 26px;
    text-align: center;

    @include from(3) {
      width: 100%;
      max-width: 400px;
    }
  }
}

.countdownTitle {
  margin-bottom: 30px;
  font-weight: 600;
}

.countdownInfo {
  margin-bottom: 30px;
}

.countdownTimer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 15px 0 20px;
  color: $grey900;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.label {
  font-size: 20px;
  line-height: 26px;
}

.time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 65px;
  font-size: 22px;
  line-height: 28px;
}
