@import 'breakpoints.scss';
@import 'colors.scss';

.imageWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.imageWrapper img {
  width: 100%;
  height: auto;
  margin: 15px 0;

  @include from(3) {
    max-width: 400px;
  }
}

.planHighlights {
  width: 100%;

  .planTitle {
    font-size: 18px;
    font-weight: 800;
    line-height: 23px;
  }

  .planList {
    padding: 0;
  }

  .highlight {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.weightLossChartWrapper {
  margin: 15px 0;
  padding: 15px;
  border-radius: 10px;
  background-color: $white;
}

.chartLoading {
  padding: 0;

  img {
    max-width: 170px;
    max-height: 280px;
  }
}
