@import 'breakpoints.scss';
@import 'colors.scss';
@import 'utils.scss';

.items {
  display: grid;
  // grid-column-gap: 15px;
  // grid-row-gap: 28px
  gap: 28px 15px;
  grid-template-columns: 3fr 1fr;
  width: 100%;
  padding-top: 14px;
  color: $grey800;
  font-size: 16px;
  line-height: 20px;
}
