@import 'colors.scss';

.title.title {
  font: inherit;
  font-weight: 400;
}

.emphasized {
  font-weight: 600;
}

.strikethrough {
  text-decoration: line-through;
}

.subtext {
  font-size: 14px;
  line-height: 20px;
}

.amount {
  text-align: right;
}

.promoTotal {
  display: flex;
  justify-self: flex-end;
  gap: 10px;
}

.promoPercent {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.promoHighlight {
  color: #008000;
  font-weight: 600;
}
