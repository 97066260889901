@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid $grey75;
}

.items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.text {
  font-size: 16px;
  line-height: 20px;
}

.totalSubText {
  display: flex;
  justify-content: flex-end;

  .text {
    width: 80px;
    margin-top: 5px;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
  }
}

.subtext {
  margin-top: 15px;
  color: $grey700;
  font-size: 12px;
  line-height: 1;
}
