@import 'breakpoints.scss';
@import 'colors.scss';

.choices {
  margin: 0;
  margin-top: 20px;
  padding: 0;
  list-style: none;
}

.choice {
  display: grid;
  grid-template-columns: min-content 1fr;
  padding: 0 0.5em 12px;
  border-radius: 12px;
  font-size: 16px;
  row-gap: 4px;
}

.choice .featured {
  grid-column: 1 / -1; // best value, etc.
}

.radio {
  $diameter: 18px;

  position: relative;
  width: $diameter;
  height: $diameter;
  margin: 0 9px 10px 0;
  border: 1px solid;
  border-radius: 50%;
  background: $white;

  input {
    opacity: 0;
  }

  &::before {
    $innerDiameter: 9px;

    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: $innerDiameter;
    height: $innerDiameter;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: $white;
  }
}

@each $theme, $themeColors in $themes {
  $secondary: map-get($themeColors, 'secondary');
  $background: transparentize($secondary, 0.75);

  .#{$theme} {
    .radio {
      border-color: $secondary;
    }

    .selected .radio::before {
      background: $secondary;
    }
  }
}

.periodicBilling {
  grid-column: 2/-1;
  color: #4c585f;
  font-size: 14px;
}

.items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.planTitle {
  font-weight: normal;
}
