.container {
  display: grid;
  grid-template-columns: 22px 1fr;
  align-items: start;
  gap: 15px;
  padding: 0 14px 18px;

  h1 {
    margin-top: -3px;
    font-size: 15px;
    letter-spacing: 0.2px;
    line-height: 24px;
  }
}
